import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import classNames from "classnames";
import { Link } from "../link";
import { dataLayerPush } from "../../utility/dataLayerUtils";

const MegamenuContent = ({ data, location }) => {
  const { linkTitle, image, linkCenter, linkSx, linkDx } = data;

  const pushData = (gaDetails, positionSx) => {
    gaDetails &&
      dataLayerPush({
        event: "scelta del servizio",
        EventCategory: positionSx ? "click sulla tessera" : "click su banner",
        EventAction: location.pathname,
        EventLabel: gaDetails,
      });
  };

  const NavigationSubItem = ({ item }) => (
    <div className="navigation__subitem px-3 py-3 py-lg-0">
      <Link
        to={item.content?.pageLink?.value?.link}
        className="navigation__link d-flex align-items-start"
        onClick={() => pushData(item.content?.pageLink?.value?.link, item.content?.gaDetail, true)}
      >
        {item.content?.logo?.node && (
          <GatsbyImage
            image={getImage(item.content.logo.node)}
            className="navigation__icon mr-3"
            alt=""
          />
        )}
        <div className="d-flex flex-lg-column align-items-center align-items-lg-start pb-lg-3 mb-lg-3">
          <div className="mb-3 mb-lg-0">
            <span className="d-block font-weight-bold">
              {item.value || item.content?.pageLink?.value?.title?.value}
            </span>
            {item.content?.linkText?.value ? (
              <p className="navigation__description mt-1">{item.content.linkText.value}</p>
            ) : null}
          </div>
        </div>
      </Link>
    </div>
  );

  return (
    <div className="navigation__wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-lg-8 d-flex flex-column pt-lg-4">
            <div className="row">
              <div className={classNames(linkCenter?.length ? "col-lg-8 px-0 px-lg-3" : "col-12")}>
                <div
                  className={classNames("row navigation__list px-0 pb-lg-3", {
                    "mr-lg-3": !linkCenter?.length,
                  })}
                >
                  <div className="col-lg-6 px-0">
                    {linkTitle
                      ?.filter((_item, i) => i % 2 === 0)
                      ?.map((item, i) => (
                        <NavigationSubItem item={item} key={i} />
                      ))}
                  </div>
                  <div className="col-lg-6 px-0">
                    {linkTitle
                      ?.filter((_item, i) => i % 2 !== 0)
                      ?.map((item, i) => (
                        <NavigationSubItem item={item} key={i} />
                      ))}
                  </div>
                </div>
              </div>
              {!!linkCenter?.length && (
                <div className="col-lg-4 d-none d-lg-block pl-lg-5 pt-lg-3">
                  {linkCenter.map((item, j) => (
                    <Link key={j} to={item.value?.link} className="navigation__textLink">
                      {item.content?.linkCenterText?.value}
                    </Link>
                  ))}
                </div>
              )}
            </div>
            {((linkSx?.value?.link && linkSx.content?.linkSxText?.value) ||
              (linkDx?.value?.link && linkDx.content?.linkDxText?.value)) && (
              <hr className="navigation__hr d-none d-lg-block w-100 mt-auto mb-0"></hr>
            )}
          </div>
          <div className="col-12 col-lg-4 px-0">
            <div className="banner h-100">
              <div className="banner__box h-100">
                {image.content && (
                  <Link
                    to={image.content.imagePage?.value?.link}
                    className="banner__link h-100 text-center text-lg-left"
                    onClick={() =>
                      pushData(
                        image.content?.imagePage?.value?.link,
                        image.content?.gaDetailDx,
                        false
                      )
                    }
                  >
                    {image?.node && (
                      <GatsbyImage
                        image={getImage(image.node)}
                        alt={image.value?.description || ""}
                        className="banner__img"
                      />
                    )}
                    <div className="">
                      <div className="banner__title">{image.content.imageTitle?.value}</div>
                      {image.content.imageText?.value && (
                        <div className="banner__subtitle">{image.content.imageText.value}</div>
                      )}
                    </div>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
        {((linkSx?.value?.link && linkSx.content?.linkSxText?.value) ||
          (linkDx?.value?.link && linkDx.content?.linkDxText?.value)) && (
          <div className="py-4 px-4 d-none d-lg-block">
            <div className="row">
              {linkSx?.value?.link && linkSx.content?.linkSxText?.value && (
                <div className="col-lg-8">
                  <Link to={linkSx.value.link} className="navigation__bottom-link">
                    {linkSx.content.linkSxText.value}
                  </Link>
                </div>
              )}
              {linkDx?.value?.link && linkDx.content?.linkDxText?.value && (
                <div className="col-lg-4">
                  <Link to={linkDx.value.link} className="navigation__bottom-link">
                    {linkDx.content.linkDxText.value}
                  </Link>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MegamenuContent;
export const fragment = graphql`
  fragment JskLayoutMenu on LiferayWebContent {
    ... on LiferayJskLayoutPaginaGenerica {
      title {
        value
      }
      link
    }
    ... on LiferayJskLayoutPaginaProdotto {
      title {
        value
      }
      link
    }
    ... on LiferayJskLayoutPaginaHub {
      title {
        value
      }
      link
    }
    ... on LiferayJskLayoutLandingOfferte {
      title {
        value
      }
      link
    }
  }
`;
