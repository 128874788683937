import classNames from "classnames";
import React from "react";
import icons_svg from "../../images/svg-icons.svg";

function Icon({ name, cls = "", style, alt }) {
  return (
    <svg className={classNames("icon", cls && cls)} style={style} alt={alt}>
      <use xlinkHref={icons_svg + "#" + name}></use>
    </svg>
  );
}

export default Icon;
