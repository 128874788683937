import React from "react";

const BannerMaintenance = ({ text, backgroundColor }) => {
  return (
    <div className="banner-maintenance" style={{ backgroundColor: backgroundColor?.value }}>
      {text?.value && <div dangerouslySetInnerHTML={{ __html: text.value }} />}
    </div>
  );
};

export default BannerMaintenance;
