import { useEffect } from "react";

const useBodyClass = (active, className) => {
  useEffect(() => {
    if (active) {
      document.body.classList.add(className);
      return () => document.body.classList.remove(className);
    }
  }, [active, className]);
};

export default useBodyClass;
