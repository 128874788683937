import PropTypes from "prop-types";
import React, { useState } from "react";
import logo from "../images/logo-engie-blue.svg";
import Icon from "./elements/icon-svg";
import Loadable from "@loadable/component";

const Modal = Loadable(() => import("react-overlays/Modal"));

const renderBackdrop = (props) => (
  <div className="modale modale__backdrop processi-inself__modal-backdrop" {...props} />
);

const HeaderFlusso = ({ siteTitle, state, dispatch }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const closeModal = () => setModalVisible(false);

  return (
    <div className="header header--flusso">
      <div className="header__group">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="header__top d-flex align-items-center justify-content-between">
                <button className="header__move-button" onClick={() => dispatch({ type: "PREV" })}>
                  {state.stepFlusso > 0 && <Icon name="chevron-left" cls="material-svg" />}
                </button>
                <div className="header__logo">
                  <img src={logo} className="h-100" alt={siteTitle} />
                </div>
                <button className="header__move-button" onClick={() => setModalVisible(true)}>
                  <Icon name="close" cls="material-svg" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={modalVisible}
        onHide={closeModal}
        className="modale-wrapper"
        renderBackdrop={renderBackdrop}
      >
        <div className="modale processi-inself__modal">
          <div className={"modale__content d-flex flex-lg-row justify-content-center"}>
            <button
              className="modale__close-btn processi-inself__modal-close-btn"
              onClick={closeModal}
            >
              <Icon name="close" cls="material-svg" />
            </button>
            <div className="processi-inself__btn-container">
              <h2 className="processi-inself__modal-title">Vuoi abbandonare la sessione?</h2>
              <div className="w-100 text-center mt-5">
                <button
                  className="processi-inself__btn w-100"
                  style={{ fontSize: "14px" }}
                  onClick={() => {
                    dispatch({ type: "CANCEL" });
                    window.open("/", "_self");
                  }}
                >
                  <strong>sì, torna alla home</strong>
                </button>
              </div>
              <div className="w-100 text-center mt-3">
                <button
                  className="processi-inself__btn processi-inself__btn--secondary w-100"
                  onClick={() => {
                    setModalVisible(false);
                  }}
                  style={{ fontSize: "14px" }}
                >
                  <strong>annulla</strong>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

HeaderFlusso.propTypes = {
  siteTitle: PropTypes.string,
};

HeaderFlusso.defaultProps = {
  siteTitle: ``,
};

export default HeaderFlusso;
