import { useState, useEffect } from "react";

const useNomePacchetto = () => {
  const [nomePacchetto, setNomePacchetto] = useState();
  useEffect(() => {
    const listener = (e) => {
      setNomePacchetto(e.detail.nomePacchetto);
    };
    document.addEventListener("set-nome-pacchetto", listener);
    return () => document.removeEventListener("set-nome-pacchetto", listener);
  }, []);
  return nomePacchetto;
};

export default useNomePacchetto;
