import { useCallback } from "react";

export const dataLayerStr2Obj = (string) => {
  return (
    string &&
    JSON.parse(
      string
        .replace("<p>", "")
        .replace("</p>", "")
        .replace(/'([^'"]+)'/g, '"$1"')
    )
  );
};

export const dataLayerPush = (event) => {
  //console.info("Push on dataLayer:", event);
  if (window.dataLayer && event) {
    window.dataLayer.push(event);
  }
};

export const useCentriAssistenzaDataLayer = () => {
  const centriAssistenzaPush = useCallback(
    (ctaType, ctaPosition, centroAssistenza) =>
      dataLayerPush({
        event: "select_content",
        cta_type: ctaType,
        cta_category: "centri assistenza",
        cta_position: ctaPosition,
        centro_assistenza: centroAssistenza,
      }),
    []
  );

  return [centriAssistenzaPush];
};
