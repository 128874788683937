import PropTypes from "prop-types";
import React from "react";

import logo from "../images/logo-engie-blue.svg";

const HeaderSmall = ({ siteTitle, location }) => {
  return (
    <div className="header header--small">
      <div className="header__group">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="header__top d-flex align-items-center justify-content-center">
                <div className="header__logo text-center">
                  <img src={logo} className="h-100" alt={siteTitle} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

HeaderSmall.propTypes = {
  siteTitle: PropTypes.string,
};

HeaderSmall.defaultProps = {
  siteTitle: ``,
};

export default HeaderSmall;
