import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import classNames from "classnames";
import { Link } from "./link";
import SitesSelect from "./sites-select";
import Navigation from "./navigation";
import useBodyClass from "../utility/useBodyClass";
import useClickOut from "../utility/useClickOut";
import Icon from "./elements/icon-svg";
import logo from "../images/logo-engie-blue.svg";
import logoCompact from "../images/logo-engie-small-blue.svg";
import BannerApp from "./elements/banner-app";
import BannerMaintenance from "./elements/banner-maintenance";

const Header = ({ siteTitle, hasBanner, location }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = useCallback(() => setMenuOpen((value) => !value), []);
  const [selectOpen, setSelectOpen] = useState(false);
  const toggleSelect = useCallback(() => setSelectOpen((value) => !value), []);
  // const [searchOpen, setSearchOpen] = useState(false);
  // const toggleSearch = useCallback(() => setSearchOpen((value) => !value), []);

  useBodyClass(menuOpen || selectOpen, "block-scroll-mobile");
  // useBodyClass(searchOpen, 'block-scroll-modal');

  const menuRef = useClickOut(menuOpen, setMenuOpen);
  const selectRef = useClickOut(selectOpen, setSelectOpen);
  // const searchRef = useClickOut(searchOpen, setSearchOpen);

  const { topMenu, bannerMaintenance } = useStaticQuery(graphql`
    {
      topMenu: liferayJskTopMenu {
        title {
          value
          content {
            page {
              value {
                ... on LiferayJskLayoutPaginaGenerica {
                  title {
                    value
                  }
                  link
                }
                ... on LiferayJskLayoutPaginaProdotto {
                  title {
                    value
                  }
                  link
                }
                ... on LiferayJskLayoutPaginaHub {
                  title {
                    value
                  }
                  link
                }
                ... on LiferayJskLayoutLandingOfferte {
                  title {
                    value
                  }
                  link
                }
              }
            }
            link {
              value
            }
            titleColor {
              value
            }
          }
        }
      }
      bannerMaintenance: liferayJskBannerMaintenance {
        showBanner {
          value
        }
        text {
          value
        }
        backgroundColor {
          value
        }
      }
    }
  `);

  const [bannerVisible, setBannerVisible] = useState(hasBanner);

  return (
    <div
      className={classNames("header", {
        "header--banner": (hasBanner && bannerVisible) || bannerMaintenance.showBanner?.value,
      })}
    >
      {bannerMaintenance.showBanner?.value && (
        <BannerMaintenance
          text={bannerMaintenance.text}
          backgroundColor={bannerMaintenance.backgroundColor}
        />
      )}
      {hasBanner && <BannerApp visible={bannerVisible} setVisible={setBannerVisible} />}
      <div className="header__group">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="header__top d-flex align-items-center">
                <div className="d-inline-flex align-items-center pl-0 header__top-left">
                  {/* da indagare */}
                </div>
                <div className="header__logo text-center">
                  <Link className="header__link h-100" to="/">
                    <img src={logo} className="h-100" alt={siteTitle} />
                  </Link>
                </div>
                <div className="d-inline-flex justify-content-end align-items-center px-0 header__top-right">
                  {topMenu?.title?.map((item, i) => (
                    <Link
                      to={
                        item.content?.page?.value?.link ||
                        (item.content?.link?.value.includes("/spazio-clienti?content=ACMADashboard")
                          ? process.env.GATSBY_SPAZIO_CLIENTI_URL
                          : item.content?.link?.value)
                      }
                      className="header__link d-none d-lg-inline-block mr-3"
                      style={{ color: item.content?.titleColor?.value }}
                      key={i}
                    >
                      {item.value}
                    </Link>
                  ))}
                  <a
                    className="header__user"
                    href={process.env.GATSBY_SPAZIO_CLIENTI_URL}
                    aria-label="Spazio clienti"
                  >
                    <Icon name="user" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 position-static">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-inline-flex align-items-center w-100">
                  <div className="header__logo-compact mr-3">
                    <Link className="header__link w-100" to="/">
                      <img src={logoCompact} className="w-100" alt={siteTitle} />
                    </Link>
                  </div>
                  <SitesSelect open={selectOpen} onClick={toggleSelect} ref={selectRef} />
                </div>
                <Navigation open={menuOpen} ref={menuRef} location={location} />
                <div className="header__buttons d-inline-flex align-items-center ml-auto">
                  <button
                    className={classNames("hamburger d-lg-none", {
                      open: menuOpen,
                    })}
                    aria-label="Navigation menu"
                    onClick={toggleMenu}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {searchOpen && <Search toggleSearch={toggleSearch} ref={searchRef} />} */}
    </div>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
