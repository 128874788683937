export const validatePhoneIT = (tel, minLength, maxLength, psychosis) => {
  if (tel) {
    const phoneNumber = tel?.replace(/\s+/g, "");
    return (
      ((minLength ? phoneNumber.length >= minLength : true) &&
        (maxLength ? phoneNumber.length <= maxLength : true) &&
        (psychosis ? phoneNumber.match(/^[^#;/]+$/) : true) &&
        phoneNumber.match(
          /^([+]39)?((3[\d]{2})(([ ,\-,/])|([ .-])){0,1}([\d, ]{6,9}))|([.,0-9]{2})\2([.,0-9]{2})\2([.,0-9]{3})|(((0[\d]{1,4}))(([ ,\-,/])|([ .-])){0,1}([\d, ]{5,10}))$/
        )) ||
      "Specificare un numero di telefono valido."
    );
  } else {
    return "Per favore compila questo campo per farti contattare.";
  }
};
