import React from "react";
import logo from "../images/logo-engie-blue.svg";

export const WidgetContattiPrivacy = ({ setShow }) => {
  const iAgree = () => {
    if (document.forms["formNomeTel"] && !document.forms["formNomeTel"].personalData.checked) {
      document.forms["formNomeTel"].personalData.disabled = false;
      document
        .getElementById("formNomeTel_form__checkbox-wrapper_personalData")
        .classList.remove("form__checkbox-wrapper--blocked");
    }
    if (document.forms["formCaldaia"] && !document.forms["formCaldaia"].personalData.checked) {
      document.forms["formCaldaia"].personalData.disabled = false;
      document
        .getElementById("formCaldaia_form__checkbox-wrapper_personalData")
        .classList.remove("form__checkbox-wrapper--blocked");
    }
  };
  return (
    <div className="privacy-modal__wrapper">
      <div className="privacy-modal__scroll">
        <div>
          <div className="privacy-modal__text">
            <p align="center">
              <b>INFORMATIVA PRIVACY PER RICHIESTA DI CONTATTO TELEFONICO</b>
            </p>
            <p>&nbsp;</p>
            <p>
              Se sei arrivato fin qui significa che vuoi sapere cosa fa ENGIE Italia S.p.A. (
              <b>&quot;Engie&quot;</b>) con i dati personali (&quot;Dati Personali&quot; o
              &quot;Dati&quot;) che ti vengono chiesti nell'ambito della richiesta di contatto
              telefonico. Qui di seguito alcune informazioni che contribuiscono a chiarire questo
              aspetto e che vogliamo darti in linea con quanto previsto dal Regolamento Generale
              Europeo sulla Protezione dei Dati (<b>&quot;Regolamento&quot;</b>).
              <br />
            </p>
            <p>&nbsp;</p>
            <p>
              <b>1. A chi sto dando i miei Dati?</b>
            </p>
            <p>&nbsp;</p>
            <p>
              A ENGIE Italia S.p.A., P.IVA 06289781004, con sede legale a Milano, Via Chiese 72 la
              quale agisce come titolare del trattamento.
            </p>
            <p>&nbsp;</p>
            <p>
              <b>2. A chi posso chiedere informazioni circa il trattamento dei miei Dati?</b>
            </p>
            <p>&nbsp;</p>
            <p>
              Al Data Protection Officer (responsabile della protezione dei dati) di ENGIE
              all&rsquo;indirizzo email dpo-ita@engie.com.
            </p>
            <p>
              A questo indirizzo potranno essere chieste informazioni su come ENGIE tratta i suoi
              Dati Personali ed altre informazioni al riguardo.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>3. Quali sono i Dati oggetto di trattamento?</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              Oggetto di trattamento sono i tuoi dati anagrafici (nome e cognome), il Comune
              rispetto al quale vuoi chiedere informazioni, il tuo numero di telefono e la tua
              e-mail.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>4. Per quale finalit&agrave; mi vengono chiesti i miei Dati?</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              Il trattamento dei tuoi Dati Personali avverr&agrave; solamente per dare seguito alla
              tua richiesta di contatto al fine di ottenere informazioni su un determinato servizio
              specifico o una determinata offerta.
            </p>
            <p>
              Se temi che ENGIE voglia utilizzare i tuoi dati per altre finalit&agrave;, ti
              rassicuriamo. Non &egrave; cos&igrave;.
            </p>
            <p>&nbsp;</p>
            <p>
              <b>
                5. Quale &egrave; la ragione per cui vengono trattati i miei Dati e cosa succede se
                non autorizzo ENGIE?{" "}
              </b>
            </p>
            <p>&nbsp;</p>
            <p>
              Il trattamento dei tuoi Dati Personali &egrave; basato sul tuo consenso quindi sei del
              tutto libero di decidere se e come procedere.
            </p>
            <p>
              Se vuoi essere contattato da ENGIE e ci autorizzi in tal senso, verrai chiamato al
              numero di telefono che hai fornito e ti daremo le informazioni che desideri.
            </p>
            <p>Se invece non vuoi essere contattato, basta che non ci autorizzi.</p>
            <p>
              Pu&ograve; capitare di cambiare idea e quindi potrai anche revocare un consenso
              gi&agrave; fornito. In tal caso però, chiaramente, sono fatte salve le eventuali
              attivit&agrave; svolte sulla base del consenso dato.&nbsp;
            </p>
            <p>&nbsp;</p>
            <p>
              <b>6. Per quanto tempo vengono conservati i miei Dati personali?</b>
            </p>
            <p>&nbsp;</p>
            <p>
              I tuoi Dati Personali saranno conservati negli archivi informatici di ENGIE e protetti
              da idonee misure di sicurezza, per il tempo necessario al raggiungimento delle
              finalit&agrave; di cui al precedente paragrafo 4. A seconda delle circostanze,
              &egrave; difficile quantificare precisamente il periodo; in ogni caso useremo criteri
              di proporzionalit&agrave;, ragionevolezza e minimizzazione solo per gestire il
              contatto telefonico che ci richiedi.
            </p>
            <p>
              I tuoi Dati Personali potrebbero essere conservati per un periodo successivo in caso
              di eventuali contenziosi, richieste delle Autorit&agrave; competenti o ai sensi di
              legge.
            </p>
            <p>&nbsp;</p>
            <p>
              <b>7. Come vengono trattati i miei Dati Personali? </b>
            </p>
            <p>&nbsp;</p>
            <p>
              Il trattamento dei Dati Personali avviene mediante strumenti informatici e manuali,
              con logiche strettamente correlate alla finalit&agrave; di contatto telefonico che ci
              chiedi e, comunque, in modo da garantire la sicurezza degli stessi e sempre nel
              rispetto delle previsioni di legge.
            </p>
            <p>&nbsp;</p>
            <p>
              <b>8. Chi sono i soggetti che accedono ai miei Dati Personali?</b>
            </p>
            <p>&nbsp;</p>
            <p>
              I tuoi Dati Personali sono trattati da personale autorizzato di ENGIE ma anche da
              soggetti esterni specificamente incaricati da ENGIE (per esempio call center o
              consulenti esterni)
            </p>
            <p>
              Non si può escludere che in determinati casi, i tuoi Dati Personali potrebbero essere
              comunicati all&rsquo;Autorit&agrave; di Pubblica Sicurezza, o altre pubbliche
              autorit&agrave;, a seguito di una richiesta di quest&rsquo;ultima di cui ENGIE sia
              destinataria.
            </p>
            <p>
              I tuoi Dati Personali non verranno trasferiti al di fuori dell&rsquo;Unione Europea e
              non vengono ceduti o diffusi.
            </p>
            <p>&nbsp;</p>
            <p>
              <b>
                9. Ho qualche diritto in relazione al fatto che vi lascio i miei Dati Personali?
              </b>
            </p>
            <p>&nbsp;</p>
            <p>In qualsiasi momento, avrai diritto di:</p>
            <p>&nbsp;</p>
            <ul>
              <li>
                ottenere da ENGIE la conferma che sia o meno in corso un trattamento dei tuoi Dati
                Personali e in tal caso, di ottenere l&rsquo;accesso alle informazioni di cui
                all&rsquo;art. 15 del Regolamento;
              </li>
              <li>
                ottenere la rettifica dei Dati inesatti che ti riguardano, ovvero, tenuto conto
                delle finalit&agrave; del trattamento, l&rsquo;integrazione dei dati personali
                incompleti;
              </li>
              <li>
                ottenere la cancellazione dei tuoi Dati Personali, in presenza di uno dei motivi di
                cui all&rsquo;art. 17 del Regolamento;
              </li>
              <li>
                ottenere la limitazione del trattamento dei tuoi dati personali nei casi previsti
                dalla legge applicabile;
              </li>
              <li>
                opporti al trattamento dei propri Dati Personali per particolari motivi ove non ci
                siano legittimi interessi cogenti di ENGIE;
              </li>
              <li>
                ricevere in un formato strutturato (es. dvd, usb), di uso comune e leggibile da
                dispositivo automatico i Dati Personali che ti riguardano forniti nonché di
                trasmettere tali dati a un altro titolare del trattamento senza impedimenti da parte
                di ENGIE se tecnicamente possibile, nei casi e nei limiti di cui all&rsquo;art. 20
                del Regolamento. A seconda dei casi e delle quantit&agrave; o complessit&agrave;
                delle informazioni richieste potrebbe esserti richiesto un contributo spese
                proporzionato al caso.
              </li>
            </ul>
            <p>&nbsp;</p>
            <p>Tali diritti potranno essere esercitati inviando:</p>
            <p>&nbsp;</p>
            <ul>
              <li>
                una comunicazione a mezzo posta elettronica all&rsquo;indirizzo
                privacy-ita@engie.com.
              </li>
            </ul>
            <p>&nbsp;</p>
            <p>
              Fatto salvo ogni altro ricorso amministrativo o giurisdizionale, hai inoltre il
              diritto di proporre reclamo ad un&rsquo;Autorit&agrave; di controllo (per
              l&rsquo;Italia: il Garante per la protezione dei dati personali), qualora tu ritenga
              che il trattamento che Ti riguarda sia effettuato in violazione del Regolamento
              generale sulla protezione dei dati. Ulteriori informazioni sono disponibili sul sito
              internet{" "}
              <a href="http://www.garanteprivacy.it" target="blank">
                http://www.garanteprivacy.it
              </a>
              . Considera che comunque potrai rivolgerti a noi per risolvere, tempestivamente e in
              modo efficace, un Tuo eventuale problema &ldquo;privacy&rdquo; con la nostra
              Societ&agrave;; comunque ci impegniamo a gestire le Tue eventuali richieste con la
              massima cortesia e discrezione.&nbsp;
            </p>

            <div className="text-center">
              <b>Informativa aggiornata a settembre 2018</b>
            </div>
          </div>
        </div>
      </div>

      <div className="privacy-modal__footer text-center mt-1">
        <button
          className="cta-privacy-ok px-4 py-2 text-center text-nowrap mr-3"
          href=""
          title="Ho letto"
          onClick={(event) => {
            event.preventDefault();
            setShow(false);
            iAgree();
          }}
        >
          Ho letto
        </button>
      </div>
    </div>
  );
};

export const SungevityPrivacy = ({ innerContent, setShow }) => {
  return (
    <div className="privacy-modal__wrapper sungevity__modal-wrapper">
      <button
        className="privacy-modal__xBtn"
        href=""
        title="Chiudi"
        onClick={(event) => {
          event.preventDefault();
          setShow(false);
        }}
      >
        X
      </button>
      {innerContent}
    </div>
  );
};

export const FormPrivacy = ({ innerContent, setShow, showCloseButton = false }) => {
  const iAgree = () => {
    if (!document.forms["contactForm"].personalData.checked) {
      document.forms["contactForm"].personalData.disabled = false;
      document
        .getElementById("contactForm_form__checkbox-wrapper_personalData")
        .classList.remove("form__checkbox-wrapper--blocked");
    }
  };
  return (
    <div className="privacy-modal__wrapper privacy-modal__wrapper--form">
      <div className="privacy-modal__container">
        {showCloseButton && (
          <button className="privacy-modal__closeBtn" onClick={() => setShow(false)}>
            X
          </button>
        )}
        <img src={logo} className="privacy-modal__logo d-block mx-auto mb-3" alt="Logo Engie" />
        <div className="privacy-modal__intro">
          Leggi tutta l'informativa privacy e clicca il pulsante a fondo pagina per confermare la
          presa visione.
        </div>
        <h2 className="privacy__title text-center">Informativa Privacy</h2>
        <hr />
        <div dangerouslySetInnerHTML={{ __html: innerContent }} />
        <div className="privacy-modal__footer text-center mt-1">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <button
            className="cta-privacy-ok px-4 py-2 text-center text-nowrap mr-3"
            href=""
            title="Ho letto"
            onClick={(event) => {
              event.preventDefault();
              setShow(false);
              iAgree();
            }}
          >
            Ho letto
          </button>
        </div>
      </div>
    </div>
  );
};

export const FormModalPrivacy = ({ innerContent, setShow }) => {
  return (
    <div className="privacy-modal__wrapper privacy-modal__wrapper--form-modal">
      <button className="privacy-modal__xBtn" onClick={() => setShow(false)}>
        X
      </button>

      <div className="privacy-modal__content" dangerouslySetInnerHTML={{ __html: innerContent }} />
    </div>
  );
};

export const LandingPrivacy = ({ innerContent, setShow }) => {
  const iAgree = () => {
    if (!document.forms["contactForm"].personalData.checked) {
      document.forms["contactForm"].personalData.disabled = false;
      document
        .getElementById("contactForm_form__checkbox-wrapper_personalData")
        .classList.remove("form__checkbox-wrapper--blocked");
    }
  };
  return (
    <div className="privacy-modal__wrapper landing__modal-wrapper">
      <img src={logo} className="privacy-modal__logo d-block mx-auto mb-3" alt="Logo Engie" />
      <div className="privacy-modal__intro">
        Leggi tutta l'informativa privacy e clicca il pulsante a fondo pagina per confermare la
        presa visione.
      </div>
      <div dangerouslySetInnerHTML={{ __html: innerContent }} />
      <div className="privacy-modal__footer text-center mt-1">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <button
          className="cta-privacy-ok px-4 py-2 text-center text-nowrap mr-3"
          href=""
          title="Ho letto"
          onClick={(event) => {
            event.preventDefault();
            setShow(false);
            iAgree();
          }}
        >
          Ho letto
        </button>
      </div>
    </div>
  );
};
