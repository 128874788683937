import React from "react";
import bgImage1920 from "../images/configuratore-prodotti/bg1920.png";
import bgImage1200 from "../images/configuratore-prodotti/bg1200.png";
import bgImage992 from "../images/configuratore-prodotti/bg992.png";
import bgImage768 from "../images/configuratore-prodotti/bg768.png";
import bgImage576 from "../images/configuratore-prodotti/bg576.png";
import classNames from "classnames";

const FooterBg = ({ cls }) => {
  return (
    <div className={classNames("footer footer--bg", cls && cls)}>
      <picture>
        <source media="(max-width: 576px)" srcSet={bgImage576} />
        <source media="(max-width: 768px)" srcSet={bgImage768} />
        <source media="(max-width: 992px)" srcSet={bgImage992} />
        <source media="(max-width: 1200px)" srcSet={bgImage1200} />
        <source media="(max-width: 1920px)" srcSet={bgImage1920} />
        <img src={bgImage1920} alt="" />
      </picture>
    </div>
  );
};

export default FooterBg;
