import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useStaticQuery, graphql } from "gatsby";
import useScroll from "../utility/useScroll";
import Header from "./header";
import Footer from "./footer";
import HeaderSmall from "./header-small";
import HeaderSmallLink from "./header-small-link";
import FooterSmall from "./footer-small";
import PreviewBar from "./preview/preview-bar";
import HeaderFlusso from "./header-flusso";
import FooterBg from "./footer-bg";
import WidgetChat from "./widget-chat";

const Layout = ({
  headerType = "standard",
  footerType = "standard",
  banner,
  children,
  location,
  recensioniVerificateId,
  state, // useful for processi inself wizard and personalizzazione offerta wizard
  dispatch, // useful for processi inself wizard and personalizzazione offerta wizard
  showChatWidget = true, // useful for webchat
}) => {
  const { site } = useStaticQuery(
    graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  );

  const scrolled = useScroll(60);

  return (
    <div
      className={classNames("wrapper", {
        "wrapper--scrolled": scrolled && headerType !== "flusso",
        "processi-inself": headerType === "flusso" && footerType === "bg",
        "personalizzazione-offerta": headerType === "flusso" && footerType === "",
      })}
    >
      <WidgetChat location={location} showChatWidget={showChatWidget} />
      {recensioniVerificateId?.value &&
        recensioniVerificateId?.content?.recensioniVerificateOn?.value && (
          <div id="id_product" custom_param={recensioniVerificateId.value}></div>
        )}
      {headerType === "standard" ? (
        <Header siteTitle={site.siteMetadata.title} hasBanner={banner} location={location} />
      ) : headerType === "small" ? (
        <HeaderSmall siteTitle={site.siteMetadata.title} />
      ) : headerType === "small-link" ? (
        <HeaderSmallLink siteTitle={site.siteMetadata.title} />
      ) : headerType === "flusso" ? (
        <HeaderFlusso siteTitle={site.siteMetadata.title} state={state} dispatch={dispatch} />
      ) : null}
      {process.env.EDIT_MODE === "true" && <PreviewBar />}
      {children}
      {footerType === "standard" ? (
        <Footer />
      ) : footerType === "small" ? (
        <FooterSmall />
      ) : footerType === "small_nolink" ? (
        <FooterSmall hideLink={true} />
      ) : footerType === "bg" ? (
        <FooterBg cls={state.stepFlusso !== 7 ? "processi-inself__hide-footer-bg" : ""} />
      ) : null}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
