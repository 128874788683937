import React, { forwardRef, useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import classNames from "classnames";
import { Link } from "./link";
import MegaMenuContentV1 from "./megamenu/megamenu-content-v1";
import MegaMenuContentV2 from "./megamenu/megamenu-content-v2";
import MegamenuV6 from "./megamenu/megamenu-v6";
import useClickOut from "../utility/useClickOut";
import Icon from "./elements/icon-svg";

const Megamenu = ({ data, location }) => {
  switch (data.__typename) {
    case "LiferayJskMegaMenuContentV1":
      return <MegaMenuContentV1 data={data} location={location} />;
    case "LiferayJskMegaMenuContentV2":
      return <MegaMenuContentV2 data={data} location={location} />;
    case "LiferayJskMegamenuV6":
      return <MegamenuV6 data={data} />;
    default:
      return null;
  }
};

const menuSmartphone = [
  {
    label: "Carbon Footprint",
    link: "https://footprint.engie.it/",
    style: "color-light-blue",
  },
  {
    label: "Info e Normative",
    link: "/info-normativa/",
  },
  {
    label: "Magazine",
    link: "/magazine/",
    style: "color-fucsia",
  },
  {
    label: "In Evidenza",
    link: "/in-evidenza/",
  },
  {
    label: "Contatti",
    link: "/contatti/",
  },
  {
    label: "Per te",
    link: "/per-te/",
  },
];

// const isActive = (page, location) => {
//   if (!location) return false;
//   if (`/${pageUrl(page)}` === location.pathname) return true;
//   const checkChildren = (page, location) => {
//     if (!page.pageChildren) return false;
//     return !!page.pageChildren.find(
//       (item) => pageLink(item) === location.pathname || checkChildren(item, location)
//     );
//   };
//   return checkChildren(page, location);
// };

// const pageLink = (page) => (page.externalLink ? page.externalLink.value : `/${pageUrl(page)}`);

const Navigation = forwardRef(({ open, langCode = "it-IT", location }, ref) => {
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const [openItem, setOpenItem] = useState(null);

  const itemClick = (e, item) => {
    if (item.value?.megamenu?.value) {
      e.preventDefault();
      setOpenItem(item);
      setSubmenuOpen(openItem !== item || !submenuOpen);
    }
  };

  useEffect(() => {
    if (!open) {
      setSubmenuOpen(false);
    }
  }, [open]);

  const subMenuRef = useClickOut(submenuOpen, setSubmenuOpen);

  const { menu } = useStaticQuery(graphql`
    fragment MegamenuFragment on LiferayWebContent {
      __typename
      ...JskMegaMenuContentV1Fragment
      ...JskMegaMenuContentV2Fragment
      ...JskMegamenuV6Fragment
    }
    {
      menu: liferayJskMenuPrincipale {
        pages {
          value {
            ... on LiferayJskLayoutPaginaGenerica {
              title {
                value
              }
              megamenu {
                value {
                  ...MegamenuFragment
                }
              }
              link
            }
            ... on LiferayJskLayoutPaginaProdotto {
              title {
                value
              }
              megamenu {
                value {
                  ...MegamenuFragment
                }
              }
              link
            }
            ... on LiferayJskLayoutPaginaHub {
              title {
                value
              }
              megamenu {
                value {
                  ...MegamenuFragment
                }
              }
              link
            }
            ... on LiferayJskLayoutLandingOfferte {
              title {
                value
              }
              megamenu {
                value {
                  ...MegamenuFragment
                }
              }
              link
            }
          }
          content {
            menuLabel {
              value
            }
          }
        }
      }
    }
  `);

  return (
    <div
      ref={ref}
      className={classNames("navigation w-lg-auto", {
        "navigation--open": open,
        "navigation--subopen": submenuOpen,
      })}
    >
      <div className="navigation__container" ref={subMenuRef}>
        <ul className="navigation__list d-flex flex-column flex-lg-row align-items-center justify-content-between">
          {menu.pages.map((item, i) => (
            <li
              className={classNames("navigation__item w-100 w-lg-auto", {
                open: item === openItem,
              })}
              key={i}
            >
              <div className="d-flex d-lg-inline-flex align-items-center">
                <Link className={`navigation__link first-level`} to={item.value?.link}>
                  <span className="font-weight-bold">
                    {item.content?.menuLabel?.value || item.value?.title?.value}
                  </span>
                </Link>
                {item.value?.megamenu?.value && (
                  <button
                    className="navigation__toggle ml-auto d-lg-none"
                    aria-label="Toggle button"
                    onClick={(e) => itemClick(e, item)}
                  >
                    <Icon name="chevron-right" cls="material-svg text-blue-corporate" />
                  </button>
                )}
                {item.value?.megamenu?.value && (
                  <button
                    className={classNames("navigation__toggle d-none d-lg-inline-flex", {
                      "navigation__toggle--open": item === openItem && submenuOpen,
                    })}
                    aria-label="Toggle button"
                    onClick={(e) => itemClick(e, item)}
                  >
                    <Icon name="expand-more" cls="material-svg" />
                  </button>
                )}
              </div>
              {item.value?.megamenu?.value && (
                <div
                  className={classNames("d-flex flex-column navigation__submenu", {
                    "navigation__submenu--open": openItem === item && submenuOpen,
                    "navigation__submenu--content": !!item.value?.megamenu?.value,
                    "navigation__submenu--banner": [
                      "LiferayJskMegaMenuContentV1",
                      "LiferayJskMegaMenuContentV2",
                    ].includes(item.value?.megamenu?.value?.__typename),
                  })}
                >
                  <button
                    className="navigation__subbutton px-2 py-3 mb-3 d-flex align-items-center d-lg-none"
                    aria-label="Indietro"
                    onClick={() => setSubmenuOpen(false)}
                  >
                    <Icon name="chevron-left" cls="material-svg text-blue-corporate d-lg-none" />
                    <span className="text-blue-corporate font-weight-bold">
                      {openItem && "Indietro"}
                    </span>
                  </button>
                  <Megamenu data={item.value?.megamenu?.value} location={location} />
                </div>
              )}
            </li>
          ))}
        </ul>
        <div className="container mt-4 pt-4 d-lg-none">
          <div className="row">
            {menuSmartphone.map((item, i) => (
              <div key={i} className="mt-3 col-6">
                <Link
                  to={item.link}
                  className={classNames("header__link header__link--big", item.style)}
                >
                  {item.label}
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
});

export default Navigation;
