import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import Cookies from "js-cookie";
import { Link } from "../link";
import { dataLayerPush } from "../../utility/dataLayerUtils";
import { StaticImage } from "gatsby-plugin-image";

const BannerApp = ({ visible, setVisible }) => {
  const [bannerType, setBannerType] = useState("desktop");
  const [mobilePlatform, setMobilePlatform] = useState("android");

  const openBannerGA = useCallback(() => {
    dataLayerPush({
      event: "Area pubblica",
      EventCategory: "Area pubblica",
      EventAction: "Banner App",
      EventLabel: "APRI - mobile",
    });
  }, []);

  const closeBannerGA = useCallback(() => {
    setVisible(false);
    Cookies.set("smartbanner_exited", "1");
    dataLayerPush({
      event: "Area pubblica",
      EventCategory: "Area pubblica",
      EventAction: "Banner App",
      EventLabel: "CHIUDI - mobile",
    });
  }, [setVisible]);

  const closeBannerDsktpGa = useCallback(() => {
    setVisible(false);
    dataLayerPush({
      event: "Area pubblica",
      EventCategory: "Area pubblica",
      EventAction: "Banner App",
      EventLabel: " CHIUDI - desktop",
    });
  }, [setVisible]);

  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        window.navigator.userAgent
      )
    ) {
      setBannerType("mobile");
      if (Cookies.get("smartbanner_exited") === "1") {
        setVisible(false);
      } else {
        if (/Android/i.test(window.navigator.userAgent)) {
          setMobilePlatform("android");
        } else if (/iPhone|iPad|iPod/i.test(window.navigator.userAgent)) {
          setMobilePlatform("ios");
        }
      }
    }
  }, [setVisible]);

  const clickBannerDsktpGA = useCallback(() => {
    dataLayerPush({
      event: "Area pubblica",
      EventCategory: "Area pubblica",
      EventAction: "Banner App",
      EventLabel: "SCOPRI DI PIU - desktop",
    });
  }, []);
  return (
    <>
      <div
        className={classNames("banner-app", {
          "d-none": !visible || bannerType !== "desktop",
        })}
      >
        <div className="banner-app__icon-wrapper">
          <StaticImage
            src="../../images/icona_appNEW2022.png"
            className="banner-app__icon"
            alt="App Engie"
            width={32}
          />
        </div>
        <div className="banner-app__text">
          Una nuova app per vivere al meglio la tua esperienza in ENGIE. Disponibile per iOS e
          Android
        </div>
        <Link to="/in-evidenza/app/" className="banner-app__cta" onClick={clickBannerDsktpGA}>
          Scopri di più
        </Link>
        <button className="banner-app__close" onClick={closeBannerDsktpGa}>
          X
        </button>
      </div>
      <div
        className={classNames("banner-app banner-app--mobile", {
          "d-none": !visible || bannerType !== "mobile",
        })}
      >
        <button className="banner-app__close" onClick={closeBannerGA}>
          X
        </button>
        <div className="banner-app__icon-wrapper">
          <StaticImage
            src="../../images/smartbanner-icon.png"
            className="banner-app__icon"
            alt="App Engie"
            width={64}
          />
        </div>
        <div className="banner-app__info">
          <div className="banner-app__info-title">ENGIE</div>
          <div className="banner-app__info-author">Engie Italia S.p.A.</div>
          <div className="banner-app__info-price">
            GRATIS {mobilePlatform === "ios" ? "- Su App Store" : "- Su Google Play"}
          </div>
        </div>
        <a
          href={
            mobilePlatform === "ios"
              ? "https://itunes.apple.com/it/app/engie-luce-gas-e-servizi/id1019118965"
              : "https://play.google.com/store/apps/details?id=it.engie.appengie&hl=it"
          }
          rel="noreferrer"
          className="banner-app__cta"
          onClick={openBannerGA}
        >
          <span>Apri</span>
        </a>
      </div>
    </>
  );
};

export default BannerApp;
