import React, { useState, useEffect } from "react";
import Icon from "./elements/icon-svg";
import classNames from "classnames";
import FormChat from "./form-chat";
import { useGenesys } from "../context/genesys";

const WidgetChat = ({ location, showChatWidget }) => {
  const { started, showChat, setShowChat } = useGenesys();
  const [openChat, setOpenChat] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [openWhatsapp, setOpenWhatsapp] = useState(false);
  const [response, setResponse] = useState();
  const [loading, setLoading] = useState(false);
  const [typeForm, setTypeForm] = useState();

  useEffect(() => {
    if (showChatWidget) {
      window.Genesys("subscribe", "Launcher.ready", function () {
        window.Genesys("command", "Launcher.show");
      });
    } else {
      window.Genesys("command", "Messenger.close");
      window.Genesys("subscribe", "Launcher.ready", function () {
        window.Genesys("command", "Launcher.hide");
      });
    }
  }, [showChatWidget]);

  useEffect(() => {
    if (window?.Genesys) {
      window.Genesys("subscribe", "Messenger.opened", (e) => {
        setLoading(false);
        if (!started.current) {
          window.Genesys("command", "Messenger.close");
        } else {
          setShowChat(false);
        }
      });

      window.Genesys("subscribe", "MessagingService.conversationCleared", (e) => {
        setOpenChat(false);
        setShowChat(true);
      });
    } else {
      let genesysValue;
      Object.defineProperty(window, "Genesys", {
        set: function (value) {
          genesysValue = value;
          genesysValue("subscribe", "Messenger.opened", (e) => {
            if (!started.current) {
              genesysValue("command", "Messenger.close");
            }
          });
        },
        get: function () {
          return genesysValue;
        },
      });
    }
  }, [started, setShowChat]);

  const showGenesys = () => {
    if (window.screen.availWidth < 576) {
      setOpenWhatsapp(true);
    } else {
      setLoading(true);
      window.Genesys(
        "command",
        "Messenger.open",
        {},
        function (o) {}, // if resolved
        function (o) {
          // if rejected
          window.Genesys("command", "Messenger.close");
        }
      );
      window.Genesys("command", "Database.set", {
        messaging: {
          customAttributes: {
            url_prov: location?.pathname,
          },
        },
      });
    }
  };

  return (
    showChatWidget && (
      <div
        className={classNames("widget-chat", { "widget-chat__hide": !showChat })}
        id="widget-chat"
      >
        <div className="widget-chat__launcher">
          <button
            className="widget-chat__button"
            type="button"
            aria-expanded="false"
            aria-label={openChat ? "Riduci a icona Messenger" : "Apri Messenger"}
            title={openChat ? "Riduci a icona Messenger" : "Apri Messenger"}
            onClick={() => {
              started.current = true;
              setOpenChat(!openChat);
            }}
          >
            <Icon
              name={openChat ? "chat-arrow" : "chat"}
              cls={openChat ? "widget-chat__icon widget-chat__icon--expanded" : "widget-chat__icon"}
            />
          </button>
        </div>
        <div
          className={classNames("widget-chat__messenger", {
            "widget-chat__messenger--expanded": openChat,
          })}
        >
          <div className="widget-chat__container">
            <div className="widget-chat__paper">
              {loading && (
                <div className="loader loader--over" role="status" aria-hidden="true"></div>
              )}
              {!(openForm || openWhatsapp) && (
                <div
                  className={classNames("widget-chat__conversation", {
                    "widget-chat__conversation--opacity": loading,
                  })}
                >
                  <button className="widget-chat__answer" onClick={() => showGenesys()}>
                    Si, sono cliente
                  </button>
                  <button
                    className="widget-chat__answer"
                    onClick={() => {
                      setTypeForm("INATT");
                      setOpenForm(true);
                    }}
                  >
                    Non ancora, ho una fornitura in fase di attivazione
                  </button>
                  <button
                    className="widget-chat__answer"
                    onClick={() => {
                      setTypeForm("PROSP");
                      setOpenForm(true);
                    }}
                  >
                    No, non sono cliente
                  </button>
                </div>
              )}
              {openForm && (
                <FormChat
                  location={location}
                  response={response}
                  setResponse={setResponse}
                  setOpenForm={setOpenForm}
                  typeForm={typeForm}
                />
              )}
              {openWhatsapp && (
                <div className="widget-chat__whatsapp">
                  <div className="row mt-4">
                    <div className="col-12 text-center">
                      <a
                        href={`https://wa.me/${process.env.GATSBY_NUMBER_WHATSAPP_CHAT}/?text=Premi invia e inizia la conversazione con l'assistente virtuale, scegli l'argomento per ricevere assistenza immediata! Web`}
                        target="_blank"
                        rel="noreferrer"
                        className="widget-chat__open-whatsapp"
                      >
                        <Icon name="whatsapp" cls="widget-chat__whatsapp-icon" />
                        Apri Whatsapp
                      </a>
                    </div>
                  </div>
                  <div className="row mt-auto">
                    <div className="col-12 text-center">
                      <button
                        className="widget-chat__restart"
                        onClick={() => setOpenWhatsapp(false)}
                      >
                        Ricomincia da capo
                      </button>
                    </div>
                  </div>
                </div>
              )}
              <div className="widget-chat__header">
                <div className="widget-chat__header-main">
                  <div className="widget-chat__heading">
                    <h1 className="widget-chat__title">
                      {openForm && !response
                        ? typeForm === "INATT"
                          ? "Ti serve aiuto? Ti chiamiamo noi!"
                          : "Vuoi conoscere le nostre offerte?"
                        : openWhatsapp
                        ? "Scrivi su Whatsapp"
                        : openForm && response === "ok"
                        ? "Richiesta inviata"
                        : openForm && response === "ko"
                        ? "Richiesta non inviata"
                        : "Sei già cliente Engie?"}
                    </h1>
                  </div>
                  <div className="widget-chat__reduce">
                    <button
                      className="widget-chat__minus-button"
                      type="button"
                      aria-expanded="false"
                      aria-label="Riduci a icona Messenger"
                      title="Riduci a icona Messenger"
                      onClick={() => {
                        started.current = true;
                        setOpenChat(!openChat);
                      }}
                    >
                      <Icon name="minus" cls="widget-chat__minus-icon" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default WidgetChat;
