import React, { useRef, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { InView } from "react-intersection-observer";
import Input from "./elements/input";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { scrollOnFormStep, submitForm, typLeadStep } from "../utility/config-form-contatti";
import useNomePacchetto from "../utility/use-nome-pacchetto";
import { validatePhoneIT } from "../utility/validate-form";
import Icon from "./elements/icon-svg";

const CLIENTIP_ENDPOINT = process.env.GATSBY_CLIENTIP_ENDPOINT;

const FormChat = ({ location, response, setResponse, setOpenForm, typeForm }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, touchedFields },
  } = useForm({ mode: "onTouched" });

  const responseRef = useRef();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [fromIP, setFromIP] = useState("127.0.0.1");
  const [userAgent, setUserAgent] = useState("");

  const getFromIP = async () => {
    const response = await fetch(
      CLIENTIP_ENDPOINT + "&_com_gdfsuez_portlet_webcontent_portlet_UserIP_t=" + Date.now()
    );
    const data = await response.json();
    // console.log('data',data)
    setFromIP(data["user-ip"]);
    // console.log('user-ip',data['user-ip'])
    setValue("fromIP", fromIP);
  };

  useEffect(() => {
    getFromIP();
  });

  useEffect(() => {
    setValue("fromIP", fromIP);
  }, [fromIP, setValue]);
  const [isBlocked, setIsBlocked] = useState(true);

  useEffect(() => {
    setUserAgent(window.navigator.userAgent);
    setValue("userAgent", window.navigator.userAgent);
  }, [userAgent, setValue]);

  const submit = async (values) => {
    if (!executeRecaptcha) {
      console.warn("Execute recaptcha not yet available");
      return;
    }
    const token = await executeRecaptcha("formCasa");

    try {
      const result = await submitForm(
        { ...values, grecaptcha: token },
        {
          provUrlType: { value: "complete" },
          prodottoInteressato: { value: typeForm },
          formAdvname: { value: "" },
        },
        location
      );
      console.log("Submit result: ", result);
      typLeadStep(typeForm, location);
      setResponse("ok");
    } catch (err) {
      console.error("Error submit: ", err);
      setResponse("ko");
    }
    if (responseRef.current) {
      responseRef.current.scrollIntoView({ behaviour: "smooth" });
    }
  };

  const nomePacchetto = useNomePacchetto();

  return (
    <>
      <InView
        as="section"
        threshold={0.1}
        triggerOnce={true}
        onChange={(inView) => inView && scrollOnFormStep(typeForm, location, nomePacchetto)}
        className="widget-chat__form"
      >
        <div className="row h-100">
          <div className="col-md-12">
            {!response && (
              <form
                name="chatForm"
                className="h-100 d-flex flex-column widget-chat__form-contatti"
                onSubmit={handleSubmit(submit)}
              >
                <Input
                  name="pdfInformativaPrivacy"
                  register={register}
                  type="hidden"
                  value={
                    typeForm === "PROSP"
                      ? "https://casa.engie.it/documents/2410672/7959010/Informativa-privacy-chatbot-non-sono-cliente-Agosto-2018.pdf"
                      : "https://casa.engie.it/documents/2410672/7959007/Informativa-privacy-chatbot-gi%C3%A0-in-attivazione.pdf"
                  }
                />
                <Input name="fromIP" register={register} type="hidden" value={fromIP} />
                <Input name="userAgent" register={register} type="hidden" value={userAgent} />
                <div className="row mb-4">
                  <div className="col-md-12">
                    <div className="widget-chat__form-title">
                      {typeForm === "INATT"
                        ? "Inserisci qui i tuoi dati e ti assisteremo nell'attivazione della fornitura."
                        : "Inserisci qui i tuoi dati e ti richiameremo per darti le informazioni di cui hai bisogno."}
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-6 mb-4 mb-md-0">
                    <Input
                      name="name"
                      register={register}
                      validation={{
                        required: {
                          value: true,
                          message: "Per favore compila questo campo per farti contattare.",
                        },
                        pattern: {
                          value: /^[a-z\u00C0-\u017F'\s]+$/i,
                          message: "Inserire solo lettere e spazi e non più di 40 caratteri",
                        },
                        maxLength: {
                          value: 40,
                          message: "Inserire solo lettere e spazi e non più di 40 caratteri",
                        },
                      }}
                      placeholder="Nome"
                      movingLabel={false}
                      iconName="user"
                      error={errors.name}
                      touched={touchedFields.name}
                      check={true}
                      checkError={true}
                    />
                  </div>
                  <div className="col-md-6">
                    <Input
                      name="surname"
                      register={register}
                      validation={{
                        required: {
                          value: true,
                          message: "Per favore compila questo campo per farti contattare.",
                        },
                        pattern: {
                          value: /^[a-z\u00C0-\u017F'\s]+$/i,
                          message: "Inserire solo lettere e spazi e non più di 40 caratteri",
                        },
                        maxLength: {
                          value: 40,
                          message: "Inserire solo lettere e spazi e non più di 40 caratteri",
                        },
                      }}
                      placeholder="Cognome"
                      movingLabel={false}
                      iconName="user"
                      error={errors.surname}
                      touched={touchedFields.surname}
                      check={true}
                      checkError={true}
                    />
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-12">
                    <Input
                      name="tel"
                      register={register}
                      validation={{
                        validate: (value) => validatePhoneIT(value, 9, 20, true),
                      }}
                      placeholder="Inserisci il tuo numero di telefono"
                      movingLabel={false}
                      iconName="phone"
                      error={errors.tel}
                      touched={touchedFields.tel}
                      check={true}
                      checkError={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-12"
                    onClick={(event) => {
                      console.log("onclick checkbox chat");
                      if (document.forms["chatForm"].personalData.disabled) {
                        let docURL = "";
                        if (typeForm === "PROSP") {
                          docURL =
                            "https://casa.engie.it/documents/2410672/7959010/Informativa-privacy-chatbot-non-sono-cliente-Agosto-2018.pdf";
                        } else {
                          docURL =
                            "https://casa.engie.it/documents/2410672/7959007/Informativa-privacy-chatbot-gi%C3%A0-in-attivazione.pdf";
                        }
                        window.open(docURL, "_blank");

                        if (!document.forms["chatForm"].personalData.checked) {
                          document.forms["chatForm"].personalData.disabled = false;
                          document
                            .getElementById("chatForm_form__checkbox-wrapper_personalData")
                            .classList.remove("form__checkbox-wrapper--blocked");
                          setIsBlocked(false);
                        }
                        event.preventDefault();
                      }
                    }}
                  >
                    <Input
                      type="checkbox"
                      name="personalData"
                      register={register}
                      validation={{
                        required: {
                          value: true,
                          message: "Per favore leggi e acconsenti alle norme sulla privacy.",
                        },
                      }}
                      label={
                        typeForm === "PROSP" ? (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: `Dichiaro di aver preso visione dell'<a target="_blank" href="https://casa.engie.it/documents/2410672/7959010/Informativa-privacy-chatbot-non-sono-cliente-Agosto-2018.pdf" title="Informativa privacy" onClick="javascript:if (!document.getElementsByName('personalData')[0].checked) {document.getElementsByName('personalData')[0].disabled = false;}">informativa</a> sul trattamento dei dati personali e autorizzo ENGIE a contattarmi con informazioni su offerte e disponibilità del servizi`,
                            }}
                          ></p>
                        ) : (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: `I tuoi dati personali saranno trattati esclusivamente per gestire la tua richiesta di assistenza sul tuo contratto con ENGIE. Per maggiori informazioni consulta l'<a target="_blank" href="https://casa.engie.it/documents/2410672/7959007/Informativa-privacy-chatbot-gi%C3%A0-in-attivazione.pdf" title="Informativa privacy"  onClick="javascript:if (!document.getElementsByName('personalData')[0].checked) {document.getElementsByName('personalData')[0].disabled = false;}">informativa privacy</a>`,
                            }}
                          ></p>
                        )
                      }
                      error={errors.personalData}
                      disabled="true"
                      blocked={isBlocked}
                      formName="chatForm"
                    />
                  </div>
                </div>
                <div className="row mt-auto">
                  <div className="col-12 text-center">
                    <button type="submit" className="widget-chat__form-submit">
                      Richiamatemi
                    </button>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-12 text-center">
                    <button className="widget-chat__restart" onClick={() => setOpenForm(false)}>
                      Ricomincia da capo
                    </button>
                  </div>
                </div>
              </form>
            )}
            {response && (
              <div className="row h-100">
                <div className="col-12 text-center">
                  <span ref={responseRef} className="scroll-anchor"></span>
                  {response === "ok" ? (
                    <div className="widget-chat__response">
                      <Icon name="circle-check" cls="widget-chat__check" />
                      <p>
                        Grazie per averci contattato, ti richiameremo al più presto con il numero
                        0239568900!
                      </p>
                      <button
                        className="widget-chat__restart"
                        onClick={() => {
                          setOpenForm(false);
                          setResponse();
                        }}
                      >
                        Ricomincia da capo
                      </button>
                    </div>
                  ) : (
                    <div className="widget-chat__response">
                      <Icon name="circle-xmark" cls="widget-chat__check" />
                      <p>
                        Servizio non disponibile,
                        <br />
                        riprova più tardi
                      </p>
                      <button
                        className="widget-chat__restart"
                        onClick={() => {
                          setOpenForm(false);
                          setResponse();
                        }}
                      >
                        Ricomincia da capo
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </InView>
    </>
  );
};

export default FormChat;
