import React from "react";
import { Link } from "./link";
import logo from "../images/logo-engie-blue.svg";

const FooterSmall = ({ hideLink }) => {
  return (
    <>
      <div className="footer footer--small">
        <div className="footer__content">
          <div className="container-fluid">
            <div className="row footer__row">
              <div className="col-md-2 text-center">
                <img src={logo} alt="Engie logo" className="footer__logo" />
              </div>
              {!hideLink && (
                <div className="col-md-2 offset-md-8 text-center text-md-left">
                  <div className="footer__link">
                    <Link to="/privacy">Privacy Policy</Link>
                  </div>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-12 p-4">
                <div className="footer__copyright text-center">
                  ENGIE ITALIA S.p.A. - P. IVA 06289781004
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterSmall;
