import React, { useMemo } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "../link";

const MegamenuV6 = ({ data }) => {
  const { imageC1, imageC2, imageD4, imageD5 } = data;

  const items = useMemo(() => {
    const res = [];
    if (imageC1?.node) {
      res.push({
        img: imageC1.node,
        link: imageC1.content?.imageCenter1Page?.value?.link,
        title: imageC1.content?.imageC1Title?.value,
        description: imageC1.content?.imageC1Text?.value,
      });
    }
    if (imageC2?.node) {
      res.push({
        img: imageC2.node,
        link: imageC2.content?.imageCenter2Page?.value?.link,
        title: imageC2.content?.imageC2Title?.value,
        description: imageC2.content?.imageC2Text?.value,
      });
    }
    if (imageD4?.node) {
      res.push({
        img: imageD4.node,
        link: imageD4.content?.imageDxPage4?.value?.link,
        title: imageD4.content?.titleD4?.value,
        description: imageD4.content?.textD4?.value,
      });
    }
    if (imageD5?.node) {
      res.push({
        img: imageD5.node,
        link: imageD5.content?.imageDxPage5?.value?.link,
        title: imageD5.content?.titleD5?.value,
        description: imageD5.content?.textD5?.value,
      });
    }
    return res;
  }, [imageC1, imageC2, imageD4, imageD5]);

  return (
    <div className="navigation__content-wrapper">
      <div className="container-fluid flex-fill">
        <div className="row">
          {items.map((box, i) => (
            <div key={i} className="col-12 col-lg-3 navigation__content">
              <Link to={box.link} className="navigation__content-link">
                {box.img && (
                  <GatsbyImage
                    image={getImage(box.img)}
                    alt={box.title}
                    className="navigation__content-img d-none d-lg-block"
                  />
                )}
                <div className="navigation__content-title font-weight-bold">{box.title}</div>
                {box.description ? (
                  <div className="navigation__content-desc font-weight-light">
                    {box.description}
                  </div>
                ) : null}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MegamenuV6;
export const fragment = graphql`
  fragment JskMegamenuV6Fragment on LiferayJskMegamenuV6 {
    liferayFields {
      siteId
      articleId
    }
    image {
      node {
        gatsbyImageData(width: 330, layout: CONSTRAINED)
      }
      content {
        imageTitle {
          value
        }
        imageText {
          value
        }
        imagePage {
          value {
            ...JskLayoutMenu
          }
        }
        externalLink {
          value
        }
        doc {
          node {
            publicURL
          }
        }
      }
    }
    linkDx {
      value {
        ...JskLayoutMenu
      }
      content {
        linkDxText {
          value
        }
      }
    }
    linkSx {
      value {
        ...JskLayoutMenu
      }
      content {
        linkSxText {
          value
        }
      }
    }
    imageC1 {
      node {
        gatsbyImageData(width: 330, layout: CONSTRAINED)
      }
      content {
        imageC1Title {
          value
        }
        imageC1Text {
          value
        }
        imageCenter1Page {
          value {
            ...JskLayoutMenu
          }
        }
        externalLink1 {
          value
        }
        doc1 {
          node {
            publicURL
          }
        }
      }
    }
    imageC2 {
      node {
        gatsbyImageData(width: 330, layout: CONSTRAINED)
      }
      content {
        imageC2Title {
          value
        }
        imageC2Text {
          value
        }
        imageCenter2Page {
          value {
            ...JskLayoutMenu
          }
        }
        externalLink2 {
          value
        }
        doc2 {
          node {
            publicURL
          }
        }
      }
    }
    imageD4 {
      node {
        gatsbyImageData(width: 330, layout: CONSTRAINED)
      }
      content {
        titleD4 {
          value
        }
        textD4 {
          value
        }
        imageDxPage4 {
          value {
            ...JskLayoutMenu
          }
        }
        externalLink4 {
          value
        }
        doc4 {
          node {
            publicURL
          }
        }
      }
    }
    imageD5 {
      node {
        gatsbyImageData(width: 330, layout: CONSTRAINED)
      }
      content {
        titleD5 {
          value
        }
        textD5 {
          value
        }
        imageDxPage5 {
          value {
            ...JskLayoutMenu
          }
        }
        externalLink5 {
          value
        }
        doc5 {
          node {
            publicURL
          }
        }
      }
    }
  }
`;
