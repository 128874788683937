import Cookies from "js-cookie";
import mapProductnameCategory from "../data/prodotti/product-name-category.json";
import { dataLayerPush } from "./dataLayerUtils";
import React from "react";
import parse, { domToReact } from "html-react-parser";

const FORM_CONTATTI_ENDPOINT = process.env.GATSBY_FORM_CONTATTI_ENDPOINT;
const FORM_COLLABORATORI_ENDPOINT = process.env.GATSBY_FORM_COLLABORATORI_ENDPOINT;
const FORM_NURTURING_ENDPOINT = process.env.GATSBY_FORM_NURTURING_ENDPOINT;

export const fasciaOrariaOptions = [
  {
    label: "Nessuna preferenza",
    value: "Nessuna preferenza",
  },
  {
    label: "09:00-12:00",
    value: "09:00-12:00",
  },
  {
    label: "12:00-15:00",
    value: "12:00-15:00",
  },
  {
    label: "15:00-17:00",
    value: "15:00-17:00",
  },
  {
    label: "17:00-19:00",
    value: "17:00-19:00",
  },
];

export const fasciaOrariaNoPreferenzaOptions = [
  {
    label: "Nessuna preferenza",
    value: "Nessuna preferenza",
  },
  {
    label: "09:00-12:00",
    value: "09:00-12:00",
  },
  {
    label: "12:00-15:00",
    value: "12:00-15:00",
  },
  {
    label: "15:00-17:00",
    value: "15:00-17:00",
  },
  {
    label: "17:00-19:00",
    value: "17:00-19:00",
  },
];

export const tipologieList = {
  pesozero: "/get-comuni-list",
  pompadicalore: "/get-comuni-list",
  clima: "/get-comuni-list",
  fotovoltaico: "/get-comuni-list",
  caldaia_manut: "/get-comuni-list",
  scaldabagno: "/get-comuni-list",
  emobility: "/get-comuni-list",
};

export const tipologieVerifica = {
  //b2c: "/get-copertura-b2-c/params/",
  pesozero: "/get-copertura-caldaia-peso-zero/params/",
  pompadicalore: "/get-copertura-pompa-di-calore/params/",
  clima: "/get-copertura-clima-peso-zero/params/",
  fotovoltaico: "/get-copertura-fotovoltaico/params/",
  caldaia_manut: "/get-copertura-manutenzione-caldaia/params/",
  scaldabagno: "/get-copertura-manutenzione-scaldabagno/params/",
  emobility: "/get-copertura-emobility/params/",
};

export const typLeadStep = (productName, location) => {
  dataLayerPush({
    event: "custompage",
    page: `/${productName}/lead/thankyou`,
    referringPath: location.origin + location.pathname,
    product: productName,
    product_category: mapProductnameCategory[productName],
  });
  dataLayerPush({
    event: "GAevent",
    eventCategory: "lead",
    eventAction: "completed",
    eventLabel: productName,
  });
};

export const submitFormConsulenza = async (values, data, location, valuesProcessiInself) => {
  const { name, surname, tel, email, fasciaOraria, personalData, grecaptcha, pdfInformativaPrivacy, fromIP, userAgent } = values;
  const { provUrlType, prodottoInteressato, formAdvname } = data;
  const submitData = {
    companyId: "10201",
    groupId: "2410672",
    ...(prodottoInteressato?.value && { advName: prodottoInteressato?.value }),
    ...(prodottoInteressato?.value === "COFFR" || prodottoInteressato?.value === "CPROD"
      ? {
          tariffaDesiderata:
            valuesProcessiInself?.sceltaPrezzo === "fisso"
              ? "Prezzo fisso"
              : valuesProcessiInself?.sceltaPrezzo === "variabile"
              ? "Prezzo variabile"
              : "Non so",
        }
      : prodottoInteressato?.value === "VOLTU"
      ? {
          fornituraInteressata:
            valuesProcessiInself?.fornituraToBe === "ele"
              ? "Luce"
              : valuesProcessiInself?.fornituraToBe === "gas"
              ? "Gas"
              : "Luce e Gas",
        }
      : null),
    ...(formAdvname?.value && { advName: formAdvname?.value }),
    urlProv: encodeURIComponent(
      provUrlType?.value?.[0] === "final"
        ? `/${location.pathname.split("/").reverse().filter(Boolean)[0]}/`
        : location.pathname
    ),
    origin: encodeURIComponent(`ENGIE_CASA@@@${Cookies.get("__utmz")}`),
    nome: name,
    ...(surname ? { cognome: surname } : null),
    telefono: tel,
    privacy: personalData ? "1" : "0",
    ...(pdfInformativaPrivacy ? { pdfInformativaPrivacy } : null),
    ...(fromIP ? { fromIP } : null),
    ...(userAgent ? { userAgent } : null),
    ...(email ? { email } : null),
    ...(fasciaOraria ? { fasciaOrariaRicontatto: encodeURIComponent(fasciaOraria) } : null),
    ...(grecaptcha ? { grecaptcha: grecaptcha } : null),
  };
  console.log("submitFormConsulenza: ", submitData);
  const fetchUrl = FORM_CONTATTI_ENDPOINT;
  const dataParams = new URLSearchParams();
  dataParams.append("jsonInfo", JSON.stringify(submitData));
  const res = await fetch(fetchUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: dataParams,
  });
  if (!res.ok) {
    throw new Error(`Error: ${res.status} ${res.statusText}`);
  }
  const result = await res.json();
  const resultData = JSON.parse(result);
  //console.log("Result: ", resultData);
  dataLayerPush({
    event: "contact_request",
    product: prodottoInteressato?.value,
    product_name:
      prodottoInteressato?.value === "ALATG"
        ? "lead_allaccio"
        : prodottoInteressato?.value === "COFFR"
        ? "lead_cambio_offerta"
        : prodottoInteressato?.value === "CPROD"
        ? "lead_cambio_prodotto"
        : "lead_voltura",
  });
  if (resultData.respCode === "ok") {
    return resultData;
  } else {
    throw new Error(`Form response not ok`);
  }
};

export const submitForm = async (values, data, location) => {
  const {
    name,
    surname,
    tel,
    email,
    comune,
    fasciaOraria,
    marcaCaldaia,
    personalData,
    grecaptcha,
    pdfInformativaPrivacy,
    fromIP,
    userAgent,
  } = values;
  console.log("submitForm.values", values);  
  const { provUrlType, prodottoInteressato, formAdvname } = data;
  
  const submitData = {
    companyId: "10201",
    groupId: "2410672",
    ...(prodottoInteressato?.value && { advName: prodottoInteressato?.value }),
    ...(formAdvname?.value && { advName: formAdvname?.value }),
    urlProv: encodeURIComponent(
      provUrlType?.value?.[0] === "final"
        ? `/${location.pathname.split("/").reverse().filter(Boolean)[0]}/`
        : location.pathname
    ),
    origin: encodeURIComponent(`ENGIE_CASA@@@${Cookies.get("__utmz")}`),
    nome: name,
    ...(surname ? { cognome: surname } : null),
    telefono: tel,
    privacy: personalData ? "1" : "0",
    ...(pdfInformativaPrivacy ? { pdfInformativaPrivacy } : null),
    ...(fromIP ? { fromIP } : null),
    ...(userAgent ? { userAgent } : null),
    ...(email ? { email } : null),
    ...(comune ? { comune } : null),
    ...(fasciaOraria ? { fasciaOrariaRicontatto: encodeURIComponent(fasciaOraria) } : null),
    ...(marcaCaldaia ? { marcacaldaia: marcaCaldaia } : null),
    ...(grecaptcha ? { grecaptcha: grecaptcha } : null),
    ...(location
      ? {
          codicePromo: location?.search
            ?.split("&")
            ?.filter((item) => item.includes("endtoend"))?.[0]
            ?.split("=")?.[1],
        }
      : null),
  };
  console.log("submitForm: ", submitData);  
  const fetchUrl = FORM_CONTATTI_ENDPOINT;
  const dataParams = new URLSearchParams();
  dataParams.append("jsonInfo", JSON.stringify(submitData));
  const res = await fetch(fetchUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: dataParams,
  });
  if (!res.ok) {
    throw new Error(`Error: ${res.status} ${res.statusText}`);
  }
  const result = await res.json();
  const resultData = JSON.parse(result);
  //console.log("Result: ", resultData);
  if (resultData.respCode === "ok") {
    return resultData;
  } else {
    throw new Error(`Form response not ok`);
  }
};

export const submitFormCollaboratori = async (values, data, location) => {
  const { tipologiaForm } = data;
  const { name, surname, tel, email, code, active, personalData } = values;
  const tipomodulo = tipologiaForm?.value?.[0].slice(-2);
  const companyId = "10201";
  const groupId = "2410672";
  const userId = "10205";
  const advIdTemp = active;
  const offer = "";
  const lead = "";
  const codiceCliente = code || "";
  const path = location?.pathname?.replace(/\//g, "@");
  const origin = `ENGIE_CASA@@@${Cookies.get("__utmz")}`;
  const params = [
    tipomodulo,
    companyId,
    groupId,
    userId,
    advIdTemp,
    personalData ? "1" : "0",
    tel,
    name,
    surname,
    offer,
    lead,
    email,
    codiceCliente,
    path,
    origin,
    "",
  ];
  //console.log("submit params: ", params);
  const fetchUrl = FORM_COLLABORATORI_ENDPOINT;
  const dataParams = new URLSearchParams();
  dataParams.append("param", params.join("#"));
  const res = await fetch(fetchUrl + "/" + dataParams, {
    method: "GET",
    //headers: {
    //  "Content-Type": "application/x-www-form-urlencoded",
    //},
    // body: dataParams,
  });
  if (!res.ok) {
    throw new Error(`Error: ${res.status} ${res.statusText}`);
  }
  const result = await res.json();
  const resultData = JSON.parse(result);
  //console.log("Result: ", resultData);
  if (resultData.registrato === "Si") {
    return resultData;
  } else {
    throw new Error(`Form response not ok`);
  }
};

export const submitFormNurturing = async (values, data, location) => {
  const { advName } = data;
  const { email, personalData, grecaptcha, pdfInformativaPrivacy, fromIP, userAgent } = values;
  const submitData = {
    companyId: "10201",
    groupId: "2410672",
    email,
    privacy: personalData ? "true" : "false",
    advName: advName?.value,
    currentUrl: location.pathname,
    ...(grecaptcha ? { grecaptcha: grecaptcha } : null),
    ...(pdfInformativaPrivacy ? { pdfInformativaPrivacy } : null),
    ...(fromIP ? { fromIP } : null),
    ...(userAgent ? { userAgent } : null),
  };
  console.log("submitFormNurturing: ", submitData);
  const fetchUrl = FORM_NURTURING_ENDPOINT;
  const dataParams = new URLSearchParams();
  dataParams.append("jsonInfo", JSON.stringify(submitData));
  const res = await fetch(fetchUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: dataParams,
  });
  if (!res.ok) {
    throw new Error(`Error: ${res.status} ${res.statusText}`);
  }
  const result = await res.json();
  //console.log("Result:", result);
  if (Object.entries(result)?.length > 0) {
    return { status: true };
  } else {
    throw new Error(`Form response not ok`);
  }
};

export const scrollOnFormStep = (productName, location, nomePacchetto) => {
  const fotov_pacchetto = nomePacchetto || "Generic";
  dataLayerPush(
    productName === "FVZER"
      ? {
          event: "custompage",
          page: `/${productName}/lead/step-1`,
          referringPath: location.origin + location.pathname,
          product: productName,
          product_category: mapProductnameCategory[productName],
          nomePacchetto: fotov_pacchetto,
        }
      : {
          event: "custompage",
          page: `/${productName}/lead/step-1`,
          referringPath: location.origin + location.pathname,
          product: productName,
          product_category: mapProductnameCategory[productName],
        }
  );
};

export const showExplodedFormLeadStep = (productName, location) => {
  dataLayerPush({
    event: "custompage",
    page: `/${productName}/lead/step-2`,
    referringPath: location.origin + location.pathname,
    product: productName,
    product_category: mapProductnameCategory[productName],
  });
};

export const koComuneLeadStep = (productName, location) => {
  dataLayerPush({
    event: "custompage",
    page: `/${productName}/lead/ko`,
    referringPath: location.origin + location.pathname,
    product: productName,
    product_category: mapProductnameCategory[productName],
  });
};

export const overlayTextParser = (privacy, setShowPrivacyModal, setShow) => {
  const opt = {
    replace: ({ attribs, children }) => {
      if (
        attribs?.class?.indexOf("open-overlay") >= 0 ||
        attribs?.class?.indexOf("close-overlay") >= 0
      ) {
        return (
          <>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href=""
              onClick={(event) => {
                event.preventDefault();
                attribs?.class?.indexOf("open-overlay") >= 0
                  ? setShowPrivacyModal(true)
                  : setShow(false);
              }}
            >
              {domToReact(children, opt)}
            </a>
          </>
        );
      }
    },
  };

  return privacy && parse(privacy, opt);
};
