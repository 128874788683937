import React, { forwardRef } from "react";
import { Link } from "./link";
import Icon from "./elements/icon-svg";

const menuCorporate = [
  {
    label: "Imprese",
    description: "Le offerte superleggere per te e per l'ambiente.",
    link: "https://imprese.engie.it/",
  },
  {
    label: "Corporate",
    description:
      "Ogni giorno conta per noi, per i nostri clienti, per i nostri partner, ma soprattutto per il nostro Pianeta.",
    link: "https://www.engie.it/",
  },
  {
    label: "Enti",
    description: "Sempre al vostro fianco, sia nel pubblico che nel privato.",
    link: "https://enti.engie.it/",
  },
  {
    label: "Sport",
    description: "Soluzioni per la redditività delle strutture sportive.",
    link: "https://sport.engie.it/",
  },
  {
    label: "Condomini",
    description: "Soluzioni per l'efficienza energetica del condominio.",
    link: "https://imprese.engie.it/condomini",
  },
];

const SitesSelect = forwardRef(({ open, onClick }, ref) => {
  return (
    <button
      className={`sites-select${
        open ? " sites-select--open" : ""
      } d-inline-flex align-items-center px-3 mr-2`}
      onClick={onClick}
      ref={ref}
    >
      <Icon name="language" cls="material-svg text-white mr-2" />
      <span className="text-white font-weight-bold d-inline-block">Casa</span>
      <Icon name="expand-more" cls="sites-select__toggle material-svg text-white ml-auto" />
      <div
        className={`w-100 d-flex flex-column sites-select__menu${
          open ? " sites-select__menu--open" : ""
        }`}
      >
        <div className="container flex-fill">
          <ul className="row sites-select__list px-0">
            {menuCorporate.map((item, i) => (
              <li key={i} className="sites-select__menuitem col-12 col-xl-3 px-3 py-3">
                <Link
                  to={item.link}
                  className="sites-select__link d-flex flex-xl-column align-items-center align-items-xl-start"
                >
                  <div className="w-100 text-left">
                    <span className="d-block font-weight-bold mb-2">{item.label}</span>
                    <p className="sites-select__description py-1">{item.description}</p>
                  </div>
                  <Icon name="chevron-right" cls="material-svg text-white ml-auto ml-xl-0" />
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </button>
  );
});

export default SitesSelect;
