import React from "react";
import { graphql } from "gatsby";
import MegamenuContent from "./megamenu-content";

const MegaMenuContentV2 = (props) => <MegamenuContent {...props} />;

export default MegaMenuContentV2;
export const fragment = graphql`
  fragment JskMegaMenuContentV2Fragment on LiferayJskMegaMenuContentV2 {
    liferayFields {
      siteId
      articleId
    }
    linkTitle {
      value
      content {
        linkText {
          value
        }
        logo {
          node {
            gatsbyImageData(width: 32, layout: FIXED)
          }
        }
        pageLink {
          value {
            ...JskLayoutMenu
          }
        }
      }
    }
    image {
      value {
        description
      }
      node {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
      content {
        imageTitle {
          value
        }
        imageText {
          value
        }
        imagePage {
          value {
            ...JskLayoutMenu
          }
        }
      }
    }
    linkSx {
      value {
        ...JskLayoutMenu
      }
      content {
        linkSxText {
          value
        }
      }
    }
    linkDx {
      value {
        ...JskLayoutMenu
      }
      content {
        linkDxText {
          value
        }
      }
    }
    linkCenter {
      value {
        ...JskLayoutMenu
      }
      content {
        linkCenterText {
          value
        }
      }
    }
  }
`;
