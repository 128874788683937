import React from "react";
import classNames from "classnames";
import Icon from "./icon-svg";

const InputComponent = ({
  type,
  name,
  register,
  validation,
  label,
  placeholder,
  showPlaceholderOpt = true,
  movingLabel = true,
  options,
  iconName,
  iconStyle,
  error,
  touched,
  isSubmitted = true,
  check,
  checkError,
  tip,
  blocked = false,
  formName,
  ...other
}) => {
  // console.log("input.blocked", blocked);
  switch (type) {
    case "checkbox":
      return (
        <label className="form__label-checkbox">
          {label}
          <span
            className={classNames("form__checkbox-wrapper", {
              "form__checkbox-wrapper--error": isSubmitted && error,
              "form__checkbox-wrapper--ok": touched && !error,
              "form__checkbox-wrapper--blocked": blocked,
            })}
            id={formName + "_form__checkbox-wrapper_" + name}
          >
            <input
              type="checkbox"
              {...register(name, validation)}
              className={classNames("form__input-checkbox form__input-checkbox--big", {
                "form__input-checkbox--error": isSubmitted && error,
                "form__input-text--ok": touched && !error,
              })}
              {...other}
            />
            <span className="checkmark"></span>
          </span>
        </label>
      );
    case "select":
      return (
        <div
          className={classNames("form__select-wrapper", {
            "form__select-wrapper--error": isSubmitted && error,
            "form__select-wrapper--ok": touched && !error,
          })}
        >
          <label htmlFor={name} className="d-none">
            {label || name}
          </label>
          {iconName && (
            <Icon
              name={iconName}
              cls="form__icon form__icon--left mr-1"
              style={iconStyle || { "--width": "13px", "--height": "15px" }}
            />
          )}
          <select
            defaultValue={label || placeholder ? "" : undefined}
            className={classNames("form__select", {
              "form__select--fixed-icon": iconName,
              "form__select--error": isSubmitted && error,
              "form__select--ok": touched && !error,
            })}
            {...register(name, validation)}
            {...other}
          >
            {label || placeholder ? (
              <option disabled value="" hidden={!showPlaceholderOpt}>
                {label || placeholder}
              </option>
            ) : null}
            {options.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        </div>
      );
    default:
      return movingLabel ? (
        <>
          <input
            type={type || "text"}
            {...register(name, validation)}
            id={name}
            className={classNames("form__input-text", {
              "form__input-text--error": isSubmitted && error,
              "form__input-text--ok": touched && !error,
            })}
            placeholder=" "
            {...other}
          />
          <label className="form__label-input-text" htmlFor={name}>
            <Icon
              name={iconName}
              cls="mr-1"
              style={iconStyle || { "--width": "13px", "--height": "15px" }}
            />
            {label}
          </label>
        </>
      ) : (
        <span
          className={classNames("form__input-text-container", {
            "form__input-text-container--error": isSubmitted && error,
            "form__input-text-container--ok": touched && !error,
          })}
        >
          <Icon
            name={iconName}
            cls="form__icon form__icon--left mr-1"
            style={iconStyle || { "--width": "13px", "--height": "15px" }}
          />
          <label className="d-none" htmlFor={name}>
            {label || name}
          </label>
          <input
            type={type || "text"}
            {...register(name, validation)}
            id={name}
            className={classNames("form__input-text form__input-text--fixed-icon", {
              "form__input-text--error": isSubmitted && error,
              "form__input-text--ok": touched && !error,
            })}
            placeholder={placeholder}
            {...other}
          />
          {check && touched && !error && (
            <Icon
              name="check"
              cls="form__icon form__icon--right"
              style={iconStyle || { "--width": "13px", "--height": "15px" }}
            />
          )}
          {checkError && error && (
            <Icon
              name="exclamation-circle"
              cls="form__icon form__icon--right"
              style={iconStyle || { "--width": "13px", "--height": "15px" }}
            />
          )}
        </span>
      );
  }
};

const Input = (props) => {
  const { type, error, isSubmitted = true, tip } = props;
  // console.log("props", props);
  return (
    <div className="form__element">
      <InputComponent {...props} />
      <span
        className={classNames(
          "form__errorMsg",
          { "form__errorMsg--block": type !== "checkbox" },
          isSubmitted && error ? "d-block" : "d-none"
        )}
      >
        {error?.message}
      </span>
      {tip && <span className="form__tipMsg">{tip}</span>}
    </div>
  );
};
export default Input;
